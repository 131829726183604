export default defineComponent({
  name: 'DataTableVirtualHorizontalScrollbar',
  props: {
    tableSize: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return () => (
      <div class="sticky bottom-0 z-[950] flex h-4 w-full">
        <div class="overflow-x-auto overscroll-contain overscroll-y-none">
          <div
            class="h-[0.5px]"
            style={{
              paddingRight: `${props.tableSize}px`,
            }}
          />
        </div>
      </div>
    )
  },
})
